import React, { useEffect, useState } from "react";
import { useEpiserver } from "@episerver/spa-core";
import { AppBar, Box, Divider, Drawer, Icon, IconButton, Slide, Toolbar, Typography, useMediaQuery, useScrollTrigger, useTheme, withWidth, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useStyles } from "./Header.style";
import Navigation from "../Navigation/Navigation";
import USPBanner from "../Navigation/USPBanner";
import Config from "../../Config";
import isomorphicMap from "../../extensions";
import { usePageContext } from "../Context/PageContextProvider";
import { placemnet } from "../../.themes/common/Theme";
import { useBrandNavigationHighligher } from "../Service/BrandNavigationHighligher";
import { LocalizedMenuLabel } from "../../enums";
import Login from "../../Components/Navigation/Login/Login";
import SearchBox from "../Search/SearchBox";
export const Header = React.memo(() => {
    const episerverState = useEpiserver();
    const { startPageData, hideMOBanner, isUSPBanner, setSelectedPage, setSelectedRootMenuItem, isServerSideRendering, } = usePageContext();
    const theme = useTheme();
    const navigationHighlighter = useBrandNavigationHighligher();
    const [showMyPages, setShowMyPages] = useState(true);
    const [showSearchBox, setShowSearchBox] = useState(true);
    const [showNavigation, setShowNavigation] = useState(true);
    const classes = useStyles({
        useMOBanner: startPageData.useMOBanner.value ?? false,
        loginIcon: startPageData.loginIcon.value?.url,
    });
    const [navigation, setNavigation] = useState(typeof prefilledDataSSR !== "undefined"
        ? JSON.parse(prefilledDataSSR.navigationData)
            .MenuItems
        : []);
    const [open, setOpen] = React.useState(false);
    const trigger = useScrollTrigger();
    const isDesktop = isServerSideRendering && typeof prefilledDataSSR !== "undefined"
        ? JSON.parse(prefilledDataSSR.customerDevice)?.isMobile.toString() ===
            "false"
        : useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchQueryValue = urlParams.get("q");
        if (searchQueryValue) {
            setShowSearchBox(false);
        }
    }, [window.location.search]);
    const menuLabel = LocalizedMenuLabel[startPageData.language.name] || LocalizedMenuLabel["default"];
    const handleDrawer = () => {
        setOpen(true);
    };
    const closeDrawer = () => {
        setOpen(false);
    };
    const fetchData = async (pageId) => {
        try {
            const response = await fetch(Config.epiBaseUrl + "api/episerver/v3/additionaldata", {
                method: "post",
                referrerPolicy: "origin",
                body: JSON.stringify({
                    PageId: pageId,
                    Href: window.location.href,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const responseJson = await response.json();
            setNavigation(responseJson.MenuItems);
            setSelectedRootMenuItem(responseJson.RootPageName);
            setSelectedPage(responseJson.PageName);
        }
        catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData(startPageData.contentLink.id.toString());
    }, [startPageData.contentLink.id]);
    const handleSearchBoxOpen = () => {
        setShowMyPages(false);
        if (!isDesktop) {
            setShowNavigation(false);
        }
    };
    const handleSearchBoxClose = () => {
        setShowMyPages(true);
        if (!isDesktop) {
            setShowNavigation(true);
        }
    };
    //move to component?
    const showLogo = ({ logoSource, logoClass, altText }) => {
        if (!logoSource)
            return;
        const wrapperLogoClassProp = (logoClass + "Wrapper");
        const logoClassProp = logoClass;
        return (React.createElement("a", { className: classes[wrapperLogoClassProp], href: startPageData.headerBlock.logoUrl.value, onClick: () => {
                setSelectedRootMenuItem("");
                setSelectedPage("");
            } },
            React.createElement("img", { className: classes[logoClassProp], src: logoSource, alt: altText })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Slide, { appear: false, direction: "down", in: open || !trigger, style: { visibility: "visible" }, onExiting: (node) => {
                const transform = `translateY(-500%)`;
                node.style.transform = transform;
                node.style.webkitTransform = transform;
            } },
            React.createElement(AppBar, { className: classes.appBar, position: "sticky" },
                isUSPBanner && !hideMOBanner && (React.createElement(USPBanner, { imageUrl: startPageData.moBannerIcon?.value?.url ?? "", text: startPageData.moBannerText.value ?? "" })),
                React.createElement(Toolbar, { className: classes.toolbar, disableGutters: true },
                    React.createElement(Box, { className: classes.headerBlock },
                        showLogo({
                            logoSource: startPageData.headerBlock?.moLogo?.value?.url,
                            logoClass: theme.header?.logoPlacement === placemnet.CENTER
                                ? "logoCentered"
                                : "logo",
                            altText: startPageData.headerBlock.altTextMoLogo?.value ??
                                "Primary logo",
                        }),
                        React.createElement("div", { className: classes.rightIconAndLinkWrapper },
                            isDesktop && !hideMOBanner && (React.createElement(Box, { className: classes.linksDesktop }, isomorphicMap(startPageData.headerBlock.siteLinks.value, (item, index) => {
                                return (React.createElement(Box, { className: classes.links, key: index },
                                    React.createElement("a", { href: item.href, onClick: () => {
                                            fetchData(item.contentLink.id.toString());
                                        }, className: clsx(classes.link, navigationHighlighter.handleLinkHighlight(item, startPageData.brand.value)
                                            ? classes.activeLink
                                            : "") }, item.text)));
                            }))),
                            showLogo({
                                logoSource: startPageData.headerBlock?.moAdditionalLogo?.value?.url,
                                logoClass: "additionalLogo",
                                altText: startPageData.headerBlock.altTextMoAdditionalLogo?.value ??
                                    "Secondary logo",
                            }))),
                    !hideMOBanner && Boolean(navigation?.length) && (React.createElement(React.Fragment, null,
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: clsx(classes.menuBarWrapper, !isDesktop && !showNavigation && classes.searchBoxOpenInMobile) },
                            isDesktop && (React.createElement(Navigation, { navigation: navigation })),
                            !isDesktop && showNavigation && (React.createElement(Box, { className: classes.menuBar },
                                React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", onClick: open ? closeDrawer : handleDrawer, className: clsx(classes.menuButton), edge: false }, open ? (startPageData.closeIcon.value?.url ? (React.createElement(Icon, { className: classes.icon },
                                    React.createElement("img", { className: classes.icon, src: startPageData.closeIcon.value?.url ?? "", alt: "" }))) : (React.createElement(CloseIcon, { color: "primary" }))) : (React.createElement(React.Fragment, null,
                                    startPageData.menuIcon.value?.url ? (React.createElement(Icon, { className: classes.icon },
                                        React.createElement("img", { className: classes.icon, src: startPageData.menuIcon.value?.url ?? "", alt: "" }))) : (React.createElement(MenuIcon, null)),
                                    React.createElement(Typography, { variant: "body1", component: "p", className: classes.menuButtonText }, menuLabel)))))),
                            React.createElement(Box, { className: classes.menuControls },
                                React.createElement(Login, { hidden: !showMyPages }),
                                startPageData.enableOptimizelyFind.value && showSearchBox && (React.createElement(SearchBox, { placeholder: startPageData.searchBoxPlaceholder.value, onSearchBoxOpen: handleSearchBoxOpen, onSearchBoxClose: handleSearchBoxClose }))))))))),
        !isDesktop && showNavigation && (React.createElement(Drawer, { variant: "persistent", anchor: "left", open: open, classes: {
                paperAnchorLeft: classes.drawerPaper,
            } },
            startPageData.headerBlock.siteLinks.value &&
                startPageData.headerBlock.siteLinks.value.length > 0 && (React.createElement(Box, { className: classes.drawerHeader }, isomorphicMap(startPageData.headerBlock.siteLinks.value, (item, index) => {
                return (React.createElement(Box, { className: classes.links, key: index },
                    React.createElement("a", { href: item.href, onClick: () => {
                            fetchData(item.contentLink.id.toString());
                        }, className: clsx(classes.link, navigationHighlighter.handleLinkHighlight(item, startPageData.brand.value)
                            ? classes.activeLink
                            : "") }, item.text)));
            }))),
            React.createElement(Divider, null),
            React.createElement(Navigation, { navigation: navigation, handleDrawer: closeDrawer }))),
        React.createElement("ul", { id: "navigation-html", className: classes.hiddenSeoHeader }, navigation &&
            isomorphicMap(navigation, (item, index) => {
                //hidden seo block with site navigation to let crawler bots to pickup the website nav even if it is not rendered yet
                return (React.createElement("li", { key: index },
                    React.createElement("a", { href: item.Link }, item.Name),
                    item.HasChildren && (React.createElement("ul", null, isomorphicMap(item.Children, (subTreeItem, index) => {
                        return (React.createElement("li", { key: index },
                            React.createElement("a", { href: subTreeItem.Link }, subTreeItem.Name),
                            subTreeItem.HasChildren && (React.createElement("ul", null, isomorphicMap(subTreeItem.Children, (innerLevelItem, index) => {
                                return (React.createElement("li", { key: index },
                                    React.createElement("a", { href: innerLevelItem.Link }, innerLevelItem.Name)));
                            })))));
                    })))));
            }))));
});
Header.displayName = "Header";
export default withWidth({ initialWidth: "xl" })(Header);
